@import '../../../styles/colors';

#product {
  padding: 10rem 0 15rem;
  color: $primary;

  .section-intro h3 {
    color: $primary;
  }

  .section-intro h1 {
    color: $primary;
  }

  .section-intro .lead {
    color: $primary;
  }
  .banner{
    text-align: center;
    .banner-img{
      height: 400px;
    }
  }

  .section{
    margin-bottom: 30px;
    .description{
      line-height: 1.5em;
    }
  }

  .ellipsis {
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  a {
    text-align: center;
    border: solid 2px $primary;
    padding: 5px 7px;
    border-radius: 5px;
    margin-right: 7px;
    margin-bottom: 7px;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-flex;

    &:hover {
      background: $primary;
      color: #d8d8d8;
      cursor: pointer;
    }
  }



  @-webkit-keyframes gradientBG {
    0% {
      background-position: 0% 50%;
    }
    100% {
      background-position: 100% 50%;
    }
    // 100% {
    //   background-position: 0% 50%;
    // }
  }
  @keyframes gradientBG {
    0% {
      background-position: 0% 50%;
    }
    100% {
      background-position: 100% 50%;
    }
    // 100% {
    //   background-position: 0% 50%;
    // }
  }


}

@media only screen and (max-width: 768px) {
  #product {
    .row.narrow.section-intro.with-bottom-sep {
      padding: 0;
    }

    &.product {
      width: 100%;
      .banner{
        text-align: center;
        .banner-img{
          height: 300px;
        }
      }
    }
  }
}

@media only screen and (max-width: 350px) {
  #products {

    .product {
      width: 100%;
    }


  }
}