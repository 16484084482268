.Count {
  width: 20%;
  bottom: 54px;
}

#home .Count h1{
  margin-bottom: 0;
  font-size: 6rem;
  opacity: 0.5;
}

@media only screen and (max-width: 768px) {
  .Count{
    width: 100%;

  }
}